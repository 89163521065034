<template>

    <el-container class="page-container" v-loading="loading">
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <tabs :tabs="tabsName" @changeTab="changeTab"></tabs>
            <el-form label-width="80px" label-position='left'>
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="优 惠 券">
                            <el-input size="medium" placeholder="请输入优惠券名称" v-model="query.name"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="handleSearch">搜索
                        </el-button>
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="clearSearch">清除
                        </el-button>

                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="{span:6,offset:6}">
                        <router-link :to="{ path: 'ticketDetail'}" style="float: right">
                            <el-button
                                    type="primary"
                                    size="medium"
                            >新建
                            </el-button>
                        </router-link>
                    </el-col>
                </el-row>

            </el-form>

            <el-table
                    :data="ticketList.list"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="优惠券ID">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="名称">
                </el-table-column>
                <el-table-column
                        label="类型">
                    <template slot-scope="scope">
                        {{ scope.row.type|filterCouponType}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="per_limit"
                        label="最大领取数">
                </el-table-column>
                <el-table-column
                        label="总数">
                    <template slot-scope="scope">
                        {{scope.row.publish_limit?'无限量':scope.row.publish_count}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="receive_count"
                        label="已领用">
                </el-table-column>
                <el-table-column
                        prop="use_count"
                        label="已使用">
                </el-table-column>
                <el-table-column
                        label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.coupons_status |filterStatus }}
                    </template>
                </el-table-column>
                <el-table-column
                        width="120px"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button type="primary" size="small">
                                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button v-if="scope.row.coupons_status===-1||scope.row.coupons_status===1||scope.row.coupons_status===4"
                                               @click="handleChangeTicketStatus(scope.row.id,back_status_text(scope.row.coupons_status).coupons_status)"
                                               size="mini" type="primary">
                                        {{back_status_text(scope.row.coupons_status).title}}
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <router-link :to="{ path: 'ticketDetail',query: {id:scope.row.id,type:scope.row.type,edit: 1} }"
                                                 v-if="scope.row.coupons_status ===-1">
                                        <el-button size="mini" type="primary">编辑</el-button>
                                    </router-link>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <router-link :to="{ path: 'ticketDetail',query: {id:scope.row.id,type:scope.row.type,edit:2} }">
                                        <el-button size="mini" type="primary">详情</el-button>
                                    </router-link>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button size="mini" type="primary" @click="createdQRCode(scope.row)" v-if="scope.row.coupons_status === 1">
                                        生成二维码
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <!-- 分页 -->
            <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="query.pageNumber"
                    :page-sizes="pageSizes"
                    :page-size="query.pageSize"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="ticketList.totalCount">
            </el-pagination>
            <!-- 生成二维码 -->
            <el-dialog
                    title="生成二维码"
                    :visible.sync="dialogVisible"
                    width="420px"
                    center>
                <el-form
                        :model="ruleForm"
                        ref="ruleForm"
                        label-width="100px"
                        class="demo-ruleForm">
                    <input type="hidden" v-model="ruleForm.id">
                    <el-form-item label="优惠券名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入优惠券名称" readonly></el-input>
                    </el-form-item>
                    <!--<el-form-item label="推广渠道" prop="promotion_channel">-->
                        <!--<el-select v-model="ruleForm.promotion_channel" placeholder="请选择推广渠道" style="width: 268px;"-->
                                   <!--@change="changeQRCode">-->
                            <!--<el-option-->
                                    <!--v-for="item in channelOption"-->
                                    <!--:key="item.id"-->
                                    <!--:label="item.name"-->
                                    <!--:value="item.id">-->
                            <!--</el-option>-->
                        <!--</el-select>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="推广门店" prop="shop_id">-->
                        <!--<el-select v-model="ruleForm.shop_id" remote :remote-method="getStationList" filterable-->
                                   <!--placeholder="请选择推广门店" style="width: 268px;" @change="changeQRCode">-->
                            <!--<el-option-->
                                    <!--v-for="item in shop_list"-->
                                    <!--:key="item.id"-->
                                    <!--:label="item.name"-->
                                    <!--:value="item.id">-->
                                <!--&lt;!&ndash;<span style="font-size: 14px">{{ item.name }}</span>&ndash;&gt;-->
                                <!--&lt;!&ndash;<span style=" color: #8492a6; font-size: 12px">{{ item.address }}</span>&ndash;&gt;-->
                            <!--</el-option>-->
                        <!--</el-select>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="子渠道编码" prop="promotion_sub_channel">-->
                        <!--<el-input v-model="ruleForm.promotion_sub_channel" placeholder="请输入子渠道编码" @input="changeQRCode"></el-input>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="推广版本标识" prop="promotion_version">-->
                        <!--<el-input v-model="ruleForm.promotion_version" placeholder="请输入版本标识" @input="changeQRCode"></el-input>-->
                    <!--</el-form-item>-->


                    <el-form-item label="链接" prop="link">
                        <el-input type="textarea" v-model="ruleForm.link" placeholder="请输入链接" rows="4" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="二维码">
                        <Vue-qr  ref="qrCode" :gifBgSrc='src' :logoSrc="src2" text="Hello world!" :size="200" :margin="10"></Vue-qr>
                    </el-form-item>
                    <div style="display: flex;justify-content: center">
                        <el-button @click="download" size="medium" type="primary">下载二维码</el-button>
                        <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
                    </div>
                </el-form>
                <!--<span slot="footer" class="dialog-footer">-->

                <!--<el-button type="primary" @click="dowmQcode" size="medium">下载</el-button>-->
                <!--</span>-->
            </el-dialog>
        </el-main>
    </el-container>

</template>

<script>
    import {getCouponsList,updStatus} from '../../api/ticketManage';
    import Tabs from '../../components/tab-bar';
    import VueQr from 'vue-qr'

    const qcodePrefix = ''
    const couponTypeOptions = [
        {
            label: '现金券',
            value: 1
        },
        {
            label: '折扣券',
            value: 2
        },
        {
            label: '满减券',
            value: 3
        }
    ];
    const statusOptions = [
        {
            label: '未上架',
            value: -1
        },
        {
            label: '上架',
            value: 1
        },
        {
            label: '过期',
            value: 2
        },
        {
            label: '下架',
            value: 4
        },
        {
            label: '已领完',
            value: 3
        }
    ];
    export default {
        components: {
            Tabs,VueQr
        },
        filters: {
            filterCouponType(val) {
                let couponType = couponTypeOptions.find(i => i.value === val);
                return couponType ? couponType.label : '';
            },
            filterStatus(val) {
                let status = statusOptions.find(i => i.value === val);
                return status ? status.label : '';
            }
        },
        data() {
            return {
                src:require('../../assets/avatar.gif'),
                src2:require('../../assets/zhifubao.png'),
                tabsName: [{name: '全部', couponsStatus: null}, {name: '未上架', couponsStatus: -1}, {
                    name: '上架',
                    couponsStatus: 1
                }, {
                    name: '下架',
                    couponsStatus: 4
                }, {name: '过期', couponsStatus: 2}, {name: '已领完', couponsStatus: 3}], // 优惠券状态  0:未上架 1:派发中 2:下架 3:过期 4:已领完',
                ticketList: {
                    list: []
                },
                loading: true,
                ticket: '',
                pageSizes: [10, 20, 30, 40],
                // 分页
                query: {
                    pageNumber: 1,
                    couponsStatus: null,
                    name: '',
                    pageSize: 10
                },
                // 弹框
                dialogVisible: false,
                // 批量发放优惠券弹窗
                dialogSendTickets: false,
                // 选择的优惠券
                ticketRow: null,
                ruleForm: {
                    id: null,
                    name: null,
                    promotion_channel: null,
                    shop_id: null,
                    promotion_sub_channel: null,
                    promotion_version: null,
                },
                config: {
                    value: '',
                    size: 240
                }
            };
        },
        methods: {
            imgType (ty) {
                let type = ty.toLowerCase().replace(/jpg/i, 'jpeg');
                let r = type.match(/png|jpeg|bmp|gif/)[0];
                return 'image/' + r;
            },
            saveFile (data, fileName) {
                let save_link = document.createElement('a');
                save_link.href = data;
                save_link.download = fileName;

                let event = document.createEvent('MouseEvents');
                event.initEvent('click', true, false);
                save_link.dispatchEvent(event);
            },
            download () {
                this.$refs.qrCode.callback((url,id)=>{
                    console.log(url,id)
                })
                console.log(this.$refs.qrCode)
                // let cans = document.querySelector('canvas');
                // let ctx = cans.getContext('2d');
                // ctx.strokeStyle = '#0695FF'; // 定义矩形的颜色
                // // ctx.strokeRect(50,50,100,100);
                // // ctx.strokeRect(50,170,100,100);
                //
                let type = 'png'; // 设置下载图片的格式
                //
                let img_png_src = this.$refs.qrCode.$el.src
                //
                let imgData = img_png_src.replace(this.imgType(type), 'image/octet-stream');
                //
                let filename = `图片${type}`; // 下载图片的文件名
                //
                this.saveFile(imgData, filename);
            },
            // 生成二维码
            createdQRCode (item) {
                this.ruleForm.name = item.name;
                this.ruleForm.id = item.id;
                this.ruleForm.link = `${qcodePrefix}?coupon_template_id=${this.ruleForm.id}`;
                for (let i in this.ruleForm) {
                    if (i !== 'link' && i !== 'name' && this.ruleForm[i]) {
                        this.ruleForm.link += `&${i}=${this.ruleForm[i]}`;
                    }
                }
                this.config.value = this.ruleForm.link;
                this.dialogVisible = true;
            },
            dowmQcode () {
                const myCanvas = this.$refs.qart;
                const type = 'image/png';
                let image = myCanvas.toDataURL(type).replace(type, 'image/octet-stream');
                window.location.href = image; // it will save locally
            },
            changeTab(couponsStatus) {
                this.query.pageNumber = 1;
                this.query.couponsStatus = couponsStatus;
                this.getTicket();
            },
            // 搜索
            handleSearch() {
                this.query.pageNumber = 1;
                this.getTicket();
            },
            clearSearch() {
                this.query.name = '';
                this.getTicket()
            },
            // 分页
            handleSizeChange(val) {
                this.query.pageSize = val;
                this.query.pageNumber = 1;
                this.getTicket();
            },
            handleCurrentChange(val) {
                this.query.pageNumber = val;
                this.getTicket();
            },
            async getTicket() {
                let {data} = await getCouponsList(this.query);
                console.log(data)
                this.ticketList = data;
            },
            handleChangeTicketStatus(id, status) {
                this.$confirm('确定修改优惠券状态？', '状态修改', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                })
                    .then(async () => {
                        let formData = {
                            id:id,
                            status:status
                        }
                        await updStatus(formData);
                        await this.getTicket();
                    });
            },
            back_status_text(coupons_status) {
                switch (coupons_status) {
                    case -1:
                        return {coupons_status: 1, title: '上架'};
                    case 1:
                        return {coupons_status: 4, title: '下架'};
                    case 4:
                        return {coupons_status: 1, title: '上架'};
                    default:
                        return {}
                }
            },
            // 关闭
            handleCancel() {
                this.dialogSendTickets = false;
                this.ticketRow = null;
            },
        },
        async mounted() {
            this.loading = false;
            await this.getTicket();
        }
    };
</script>

<style scoped>
    .qrcode {
        width: 200px;
        height: 200px;
    }
</style>

