<template>
  <div class="tabs-scroll">
    <div class="tabs">
      <div class="tab" v-for="(item,index) in tabs" :key="index" :class="couponsStatus===item.couponsStatus?'active':''"
           @click="changeTab(item.couponsStatus)">
        <div>{{item.name}}</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
    name: 'tabs',
    props: {
        tabs: {
            type: Array
        }
    },
    data () {
        return {
            couponsStatus: 0
        };
    },
    methods: {
        changeTab (index) {
            this.couponsStatus = index;
            this.$emit('changeTab', index);
        }
    },
    mounted () {
        console.log(this.tabs[0].couponsStatus)
        this.couponsStatus = this.tabs[0].couponsStatus;
    }
};
</script>

<style scoped lang="less">
  .tabs-scroll {
    overflow: hidden;
    margin-bottom: 15px;
    border-bottom: 1px solid #e4e7ed;
    box-sizing: border-box;
    .tabs {
      border: 1px solid #e4e7ed;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      box-sizing: border-box;
      overflow: hidden;
      float: left;
      margin-bottom: -1px;
      .tab {
        cursor: pointer;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        float: left;
        font-size: 14px;
        color: #303133;
        position: relative;
        border-bottom: 1px solid transparent;
        border-left: 1px solid #e4e7ed;
        transition: color .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1);
        &:first-child {
          border-left: 0;
        }
        &.active {
          color: #ffffff;
          border-color:#409eff ;
          background-color: #409eff;
          border-bottom-color: #ffffff;
        }
      }
    }

  }
</style>
